const hotDefaults = {
    autoColumnSize: false,
    autoRowSize: false,
    licenseKey: '6cc23-c27db-35964-c4b3a-7883b',
    rowHeaders: true,
    fillHandle: false,
    stretchH: 'all',
    width: '100%',
};

export { hotDefaults as default, hotDefaults };
